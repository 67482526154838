import { useHistory, useLocation } from "react-router-dom"
import { Button } from "../components/button"
import { BaseContainer } from "../components/container"
import { HeaderTitle, PageDescription } from "../components/page_sections"
import { StickyFooterGroup } from "../components/sticky_footer_group"
import { PendingDependant } from "./connections/onboard_adult"
import Action from "../components/action"
import { CenteredIconHeader, LogoHeader } from "../components/header"
import { makeStyles } from "@material-ui/core"
import { useState } from "react"
import { pluralString } from "../utils/common"
import TickCircleIcon from "../assets/icons/tick_circle"
import ActionDialog from "../components/dialogs/action_dialog"
import ClinicHouseIcon from "../assets/icons/clinic_house"
import { Step as PaperRecordStep } from "./records/paper/new"
import { isLoggedIn, getUserFullName, getEmail, getUUID } from "../utils/auth";
import { axiosAuthedInstance } from "../utils/axiosApi"
import ErrorDialog from "../components/dialogs/error_dialog"


export const isMessageSquelched = () => {
    const squelchedUntil = localStorage.getItem('missing_dtap_reminder')

    if (!squelchedUntil) return false

    return new Date() < new Date(Number(squelchedUntil))
}

export const squelchMessage = () => {
    const d = new Date()
    d.setDate(d.getDate() + 3)

    localStorage.setItem('missing_dtap_reminder', d.getTime().toString())
}

export const MissingDTaP = () => {
    const history = useHistory()
    const classes = useStyles()

    // Prompt has been seen - don't show again from dashboard for 24h
    // regardless of what happens. This covers using the back button
    // to exit the prompt
    squelchMessage()

    const confirmSkip = () => {
        history.push(`/add-dtap/${getUUID()}/ask_later`)
    }

    function handleSubmit() {
        history.push(`/add-dtap/${getUUID()}/confirm_unvaccinated`)
    }

    return (
        <BaseContainer header={<LogoHeader/>}>
            <HeaderTitle>
                Diphtheria, Tetanus, Pertussis & Polio Records
            </HeaderTitle>

            <PageDescription>
               <b>Verifying your <span className={classes.childhoodHighlight}>childhood</span> vaccination history</b>
            </PageDescription>

            <p>
                Your Imunis account currently shows no vaccination records for Diphtheria, Tetanus, Pertussis or Polio during childhood.
            </p>
            <p>
                Most adults will have been vaccinated in childhood & this may affect an adult’s Imunis vaccination status.
            </p>
            <p>
               <b>Do you believe you received at least one vaccination against diphtheria, tetanus, pertussis and polio in childhood?</b>
            </p>

            <Button
                primary
                className={classes.fullWidth}
                onClick={() => {
                    history.push(`/add-dtap/${getUUID()}/record_type`)
                }}>
                I received at least one
                dose as a child
            </Button>

            <br/><br/>

            <Button
                primary
                className={classes.fullWidth}
                onClick={handleSubmit}>
                I can’t be sure I was vaccinated /<br/>
                I was not vaccinated as a child
            </Button>

            <br/><br/>

            <Button onClick={confirmSkip} className={classes.fullWidth}>
                Ask me later
            </Button>

        </BaseContainer>
    )
}


const useStyles = makeStyles((theme) => ({
    successIcon: {
        color: theme.palette.success.main,
    },
    fullWidth: {
        width: "100%"
    },
    childhoodHighlight: {
        color: "#FF5300"
    }
}))
