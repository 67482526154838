import { useEffect, useState } from "react";
import styled from 'styled-components';
import Container from '@material-ui/core/Container';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { spacing, sizing, flexbox, display } from '@material-ui/system';
import { cx, getDisplayMode, isPhone } from '../utils/common'
import { Header } from "./app_header";
import { NavBar } from "./navbar";
import { PWADialog } from "./dialogs/pwa_dialog";
import { Link, useHistory } from "react-router-dom";
import { getMissingDtap } from '../utils/auth.ts'
import { isMessageSquelched as isDtapMessageSquelched } from "../pages/missing_dtap";

const Box = styled.div`${spacing}${sizing}${display}${flexbox}`;

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: '100%',
        padding: 0,
        paddingTop: 'env(safe-area-inset-top)',
    },
    siteContainer: {
        padding: 0,
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            maxWidth: '444px'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: 'calc(444px + 1rem)'
        }
    },
    bodyWrapper: {
        ...nestFlexStyle,
        padding: 0,
        alignItems: 'center'
    },
    safeAreaBottom: {
        paddingBottom: 'env(safe-area-inset-bottom)',
    }
}));

export const nestFlexStyle = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
};

const canShowInstall = isPhone() && getDisplayMode() === 'browser'

/**
 * <BaseContainer> is the top level component that all screens in the app should use.
 * It accepts a variety of props;
 *
 * showNav?: boolean
 * When true, will show the main navigation bar at the bottom of the page
 *
 * showCta?: boolean
 * extraComponent?: ReactNode
 * `extraComponent` is arbitrary content that sticks to the bottom of the screen. Commonly used to
 * add actions buttons. When providing `extraComponent`, `showCta` must also be set to true.
 *
 * home?: boolean
 * records?: boolean
 * sharing?: boolean
 * connection?: boolean
 * discover?: boolean
 * Controls which item on the main navigation bar is styled as active.
 * Has no effect when `showNav` is not true.
 *
 * className?: string
 * contentWrapperClass?: string
 * Used to pass arbitrary styles to the top-level and the content wrapping element.
 * Used only in the PDF page (vaccination_schedule.tsx)
 *
 * header?: ReactNode
 * noHeader?: boolean
 * If `header` is provided, uses that. Expected values are one of the exported components in `components/header.tsx`.
 * If `noHeader` is true, will not output anything. Otherwise, the 'default' header is rendered -- see `components/app_header.js`.
 * All header variants are in the Figma: https://www.figma.com/design/CIs2Lv5g9sql6koYxlinoO/Imunis_Library?node-id=2809-44695&t=hZkucW8J3egmXaaJ-0
 *
 * noPadding?: boolean
 * Used in one screen - notifications
 *
 * showOnboardingSteps?: boolean
 * Displays the "App intro" widget when set to true. Set by the dashboard page when applicable.
 */
export const BaseContainer = (props) => {
    const classes = useStyles()
    const theme = useTheme()
    const [showPWA, setShowPWA] = useState(false)
    const history = useHistory()

    // By default the app intro will be displayed when "first login" is detected. In some cases
    // like the dashboard we want the option to opt-out of the auto-detection.
    const [showOnboardingSteps, setShowOnboardingSteps] = useState(
        props.showOnboardingSteps !== false && localStorage.getItem('first_visit') === "true"
    )
    useEffect(() => {
        if (props.showOnboardingSteps) setShowOnboardingSteps(true)
    }, [props.showOnboardingSteps])

    const paddingStyle = props.noPadding ? 0 : theme.spacing(2, 3, 0)
    let pageStyle = {}
    if (props.scanner) {
        pageStyle = { width: '100vw', maxWidth: '100vw', backgroundColor: 'black' }
    }

    const showFooter = props.showCta || props.showNav

    let header = null
    if (props.header) {
        header = props.header
    } else if (!props.noHeader) {
        header = <Header
            showNav={props.showNav}
            canShowInstall={canShowInstall}
            showAddDialog={() => setShowPWA(true)}
            showOnboarding={() => setShowOnboardingSteps(true)}
        />
    }

    return (
        <Box className={cx(classes.container, props.className, !showFooter && classes.safeAreaBottom)}
            m={0} width='100%' display="flex" flexDirection="column">

            {header}

            <Container className={classes.bodyWrapper}>
                <Box className={cx(classes.siteContainer, props.contentWrapperClass)}
                    padding={paddingStyle} style={pageStyle} height="100%" width="100%">
                    {props.children}
                </Box>

                {showFooter && <NavBar
                    className={classes.safeAreaBottom}
                    {...props}
                    showOnboardingSteps={showOnboardingSteps}
                    dismissOnboarding={() => {
                        setShowPWA(true)
                        setShowOnboardingSteps(false)
                        if(!canShowInstall){
                            if (window.location.pathname == '/home'){
                                if (getMissingDtap() == 'true' && !isDtapMessageSquelched()){
                                   history.replace('/missing-dtap')
                                }
                            }
                        }
                    }}
                />}
            </Container>

            <PWADialog
                open={canShowInstall && showPWA}
                close={() => {
                        setShowPWA(false)
                        if (window.location.pathname == '/home'){
                            if (getMissingDtap() == 'true' && !isDtapMessageSquelched()){
                                history.replace('/missing-dtap')
                            }
                        }
                    }
                }
            />
        </Box>
    );
}
